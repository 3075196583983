/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        /****************
        **  Initiate Social share buttons
        *****************/
        (function(){
          socializer( '.socializer' );
        }());

        /****************
        **  Carousels
        *****************/
        $('.hero-carousel').slick({
          rows: 0,
          arrows: false,
          dots: true,
          infinite: true,
          speed: 600,
          fade: true,
          cssEase: 'linear',
          autoplay: true,
          autoplaySpeed: 6000
        });

        $('.gallery-grid').slickLightbox({
            itemSelector: '> a.gallery-item'
         });

        /****************
        **  Match Heights
        *****************/
        $('.grid-articles article .categories').matchHeight();
//           $('.grid-articles article .link-article figure').matchHeight({ byRow: false });
        $('.cta-tiles-block .tiles .cta-tile').matchHeight();
        $('.cta-tiles-block .tiles .cta-tile .details').matchHeight();
        $('.event-grid .event-item a').matchHeight();
        $('.event-grid .event-item a .details').matchHeight();
        $('.hero-carousel .container-fluid > .row').matchHeight();


        /****************
        **  Primary Navigation - overlay settings
        *****************/
        $('#PrimaryNavigation').on('shown.bs.collapse', function () {
          $('body').addClass('primary-nav-engaged');
        });
        $('#PrimaryNavigation').on('show.bs.collapse', function () {
          $('body').addClass('primary-nav-engaging');
        });
        $('#PrimaryNavigation').on('hide.bs.collapse', function () {
          $('body').removeClass('primary-nav-engaged primary-nav-engaging');
        });
        $('#SearchBar').on('show.bs.collapse', function () {
          $('body').addClass('searchbar-engaged');
        });
        $('#SearchBar').on('hide.bs.collapse', function () {
          $('body').removeClass('searchbar-engaged');
        });
        $(document).keyup(function(e) {
          if (e.keyCode === 27) { // escape key maps to keycode `27`
            $('#PrimaryNavigation').collapse('hide');
//             $('body').removeClass('primary-nav-engaged primary-nav-engaging');
          }
        });

        /****************
        **  Primary Navigation - creating headings, mobile collapse toggling
        *****************/
        $("#PrimaryNavigation ul#menu-primary-navigation > li > a").replaceWith(function(){
          return $("<span class='nav-heading'>" + $(this).html() + "</span><span class='subnav-toggle'>" + $(this).html() + "<i class='far fa-angle-down'></i></span>");
        });
        $("#PrimaryNavigation ul#menu-quicklinks-navigation > li > a").replaceWith(function(){
          return $("<span class='nav-heading'>" + $(this).html() + "</span>");
        });
        $( "#PrimaryNavigation #menu-primary-navigation > li .subnav-toggle" ).click(function(e) {
          $(this).parent('li.menu-item').toggleClass('active-open');
          $(this).next('ul.sub-menu').slideToggle();
        });


        /****************
        **  Subpage Navigation - reorganizing and formatting
        *****************/
        $("#menu-subpage-navigation > li:not(:first-child)").remove();
        $("#menu-subpage-navigation > li:first-child").after( $("#menu-subpage-navigation > li:first-child > ul.sub-menu li") );
        $("#menu-subpage-navigation > li:first-child > ul.sub-menu").remove();

        /****************
        **  SVG swap out with PNG on incompatible browsers
        *****************/
        if (!Modernizr.svg) {
          $('img[src*="svg"]').attr('src', function() {
            return $(this).attr('src').replace('.svg', '.png');
          });
        }

        /****************
        **  Insert ACF Map on Location pages
        *****************/
        $('.acf-map').each(function(){
          var map = initMap( $(this) );
        });

        /****************
        **  Close Alert Bar
        *****************/
        if( $('.alertbar').length ) {
          if (typeof Cookies.get('alertHider') === 'undefined') {
            $('body').addClass('alertbar-engaged');
          }
        }
        $(".alertbar .closealert").on('click', function (e) {
          e.preventDefault();
          $('body').removeClass('alertbar-engaged');
          Cookies.set('alertHider', true);
          return false;
        });

        /****************
        **  BLOG filtering open/close
        *****************/
        $( ".filters .filter-toggle" ).click(function(e) {
          e.preventDefault();
          $(this).toggleClass('active-open');
          $(this).next('ul').slideToggle(150);
        });

        /****************
        **  Pagination circles - width to match heights
        *****************/
        $(window).resize(function(){
          $('.pagination .nav-links .page-numbers:not(.prev):not(.next)').each(function() {
            $(this).width($(this).height());
          });
        }).resize();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('.subpage-links').fadeIn(600,'swing');

        $(window).load(function(){
          $('#SearchBar form.gsc-search-box .gsc-input input#gsc-i-id1').attr('placeholder','Search');
          $('#SearchBar form.gsc-search-box .gsc-search-button button.gsc-search-button').html('<i class="fas fa-search"></i><span>Search</span>');
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // 404 page
    'error404': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#SearchBar').collapse('show');

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // search page
    'search': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#SearchBar').collapse('show');

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Events pages
    'tribe_events_page_template': {
      init: function() {
        $('#menu-quicklinks-navigation .menu-item').removeClass('active');
        $('#menu-primary-navigation .menu-item').removeClass('active');
        $('#menu-quicklinks-navigation .menu-events-calendar').addClass('active');
      },
    },
    // Filtering page
    'page_id_8760': {
      init: function() {
        // JavaScript to be fired on the home page
        var userprofile = getParameterByName('user-profile',window.location.href);
        var pagecategory = getParameterByName('page-category',window.location.href);

        if (userprofile || pagecategory) {
          if (userprofile!==0) { $('.virtual-guide-filters select#user-profile').val(userprofile); }
          if (pagecategory!==0) { $('.virtual-guide-filters select#page-category').val(pagecategory); }
          $('.virtual-guide-filters button[type=submit]').prop('disabled', false);
        }
        $('.virtual-guide-filters select').change(function(){
          if (($('.virtual-guide-filters select#user-profile').val()!=='0') || ($('.virtual-guide-filters select#page-category').val()!=='0')) {
            $('.virtual-guide-filters button[type=submit]').prop('disabled', false);
          } else {
            $('.virtual-guide-filters button[type=submit]').prop('disabled', true);
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
